<template>
  <dashPageNew
      v-if="$store.state.auth.userType === 'superadmin'"
      title="Бізнес акаунти"
      subtitle="FineTrading"
      icon="mdi-briefcase-outline"
      :filters="filtersSelect"
      :filters-input="filters"
      @filter="filters = $event"

  >

    <v-row class="mt-3">
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsDARKLIGHT" >
          <div class="d-flex align-center">
            <v-img  aspect-ratio="1" max-width="21" class="mr-2" src="@/assets/brand/logo_fine_white_small.png" />
            <h4 class="font-weight-medium">{{ $t('FineTrading') }} : <span class="font-weight-bold">{{ fineLength }} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-img  aspect-ratio="1" max-width="25" class="mr-2" src="@/assets/brand/logo_westudy_small_white.png" />
            <h4 class="font-weight-medium">{{ $t('WeStudy') }} : <span class="font-weight-bold">{{ westudyLength }} </span></h4>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsACCENT" >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            <h4 class="font-weight-medium">{{ $t('Тріал') }} : <span class="font-weight-bold">{{ trialLength }} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-icon class="mr-2">mdi-cash</v-icon>
            <h4 class="font-weight-medium">{{ $t('Платні') }} : <span class="font-weight-bold">{{ payedLength }} </span></h4>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsSUCCESS" >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            <h4 class="font-weight-medium">{{ $t('Бізнесів') }} : <span class="font-weight-bold">{{ businesses.length}} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-icon class="mr-2">mdi-cash</v-icon>
            <h4 class="font-weight-medium">{{ $t('Місячний план') }} : <span class="font-weight-bold">{{ plannedMonthArrival}} USD</span></h4>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Main View-->
    <v-sheet class="mt-6 wsRoundedHalf pa-6">
      <!--  Businesses table -->
      <ws-data-table
          :items="items"
          :headers="headers"
          :search="filters.search"
          :loading="LOADING"
          multi-sort

      >
        <template #item="{item,index}">
          <tr @contextmenu="openMenu($event, item)" >
            <td class="py-2"  >
              <div class="d-flex">
                <v-avatar class="mr-2" :color="wsACCENT"  size="40" key="profile_picture" >
                  <v-sheet color="transparent" class="pt-1" >
                    <v-img v-if="item.project === 'westudy'" height="30" width="30" src="@/assets/brand/logo_westudy_small_white.png" />
                    <v-img v-else height="20" width="20" src="@/assets/brand/logo_fine_white_small.png" />
                  </v-sheet>
                </v-avatar>
                <div>
                  <code> {{ item.alias }}</code>
                  <h5> {{ item.name }}</h5>
                  <v-chip v-for="(tag,j) in item.tags" :key="'tag_' + index + '_' + j" x-small outlined :color="tag.color" class="px-1">
                    <h6>{{ tag.name }}</h6>
                  </v-chip>
                </div>
              </div>
            </td>
            <td>
              <h5> {{ item.user_name }}</h5>
              <h6 class="font-weight-medium"> {{ item.user_phone }}</h6>
            </td>
            <td>
              <ws-chip :color="wsSUCCESS" icon="mdi-cash" >
                <h5 :class=" item.business_plan_alias === 'trial' || item.business_plan_alias === 'westudy_trial' ? 'font-weight-light' : ''">
                  {{ item.business_plan_name }}
                </h5>
              </ws-chip>
            </td>
            <td class="text-no-wrap">
              <ws-chip :color="wsACCENT" icon="mdi-calendar" :text="item.date_created"></ws-chip>
            </td>
            <td align="center">
              <ft-select v-model="item.crm_status" @input="changeStatus($event, item )" :items="statusTypes" >
                <template v-slot:default="{text}">
                  <ws-chip :color="wsACCENT" :icon="getCRMStatusIcon(item.crm_status)"> <h5>{{ text}} </h5>  </ws-chip>
                </template>
              </ft-select>
            </td>
            <td>
              <ws-chip :color="wsACCENT" icon="mdi-account-school"> <h5>{{ item.students_count }} </h5>  </ws-chip>
            </td>
            <td>
              <ws-chip :color="wsACCENT" icon="mdi-school"> <h5>{{ item.components_count }} </h5>  </ws-chip>
            </td>
            <td>
              <ws-chip :color="wsACCENT" icon="mdi-account-tie"> <h5>{{ item.managers_count }} </h5>  </ws-chip>
            </td>
            <td>
              <ft-select left @input="editAction($event, item)" :items="editActionTypes(item)">
                <v-btn icon><v-icon :color="wsATTENTION">mdi-pencil-circle</v-icon></v-btn>
              </ft-select>

            </td>
          </tr>
        </template>
      </ws-data-table>
    </v-sheet>

    <template #dialog>

      <ws-dialog v-model="displayConfirmArchive"
                 title="Архівувати бізнес"
                 displayConfirmDelete
                 confirm-delete-text="Ви впевнені що хочете архівувати бізнес?"
                 @delete="archiveBusiness" />
      <ws-dialog v-model="displayAddEventDialog"
                 @save="addEvent"
                 :title="selectedBusiness.name + ': ' + eventType">

        <h5 class="mb-3">Назва</h5>
        <v-text-field v-model="eventEntity.name" outlined dense label="Назва"  />
        <h5 class="mb-3">Текст</h5>
        <v-textarea v-model="eventEntity.text" outlined dense label="Текст" />
        <v-row v-if="eventEntity.type !=='note'">

          <v-col cols="6">
            <h5 class="mb-3">Менеджер</h5>
            <v-select v-model="eventEntity.manager" label="Менеджер" prepend-inner-icon="mdi-account-tie" outlined dense />
          </v-col>

          <v-col cols="6">
            <h5 class="mb-3">Строк</h5>
            <ws-date-picker v-model="eventEntity.dateEnd" label="Виконати до" />
          </v-col>

        </v-row>

      </ws-dialog>

      <ft-select :expanded="displayContextMenu" :x="x" :y="y" absolute  @input="editAction($event, selectedBusiness)" :items="editActionTypes(selectedBusiness)">
      </ft-select>

    </template>

  </dashPageNew>
</template>

<script>
import {mapActions} from "vuex";
import spectatorMode from "@/mixins/spectatorMode";

export default {
  name: "education",
  mixins : [spectatorMode],
  props : ['user_id'],
  data() {
    return {
      filters : {},
      displayContextMenu : false,
      selectedEventBusinessId : null,
      x : 0,
      y : 0,
      project : 'fine',
      businesses : [],
      tagsSelect : [],
      eventType : "Нотатка",
      eventEntity : {
        type : 'note'
      },
      defaultEventEntity : {
        type : ''
      },
      filter : {},
      filterTags : [],
      search : '',


      // dsiplay variables
      displayConfirmArchive : false,
      displayAddEventDialog : false,

      // select variables
      newCardType : 'note',
      selectedBusiness : {},
    }
  },
  computed : {

    filtersSelect(){
      return [
        { text : this.$t('Status')  , value : 'status' , type : 'select' , items : this.filterStatusTypes },
        { text : this.$t('PaymentPlan')  , value : 'business_plan' , type : 'select' , items : this.filterBusinessPlans },
        { text : this.$t('Tags')  , value : 'tags' , type : 'select_multiple' , items : this.tagsSelectFiltered },
      ]
    },

    tagsSelectFiltered() {

      let items = this.tagsSelect

      items.forEach(tag => {
        if ( this.filterTags.includes(tag.value)) {
          tag.icon = 'mdi-minus'
        } else {
          tag.icon = 'mdi-plus'
        }
      })

      return items

    },

    items() {
      let items = JSON.parse(JSON.stringify(this.businesses))

      if ( this.filters.status ) {
        items = items.filter( el=> el.crm_status === this.filters.status )
      }

      if ( this.filters.business_plan ) {
        items = items.filter( el=> el.business_plan_alias === this.filters.business_plan )
      }

      if ( this.filters.tags ) {
        if ( this.filters.tags.length > 0 ) {
          items = items.filter( el=>
              el.tags.map(tag => tag.uuid).filter(tag_uuid => this.filters.tags.includes(tag_uuid)).length === this.filters.tags.length
          )
        }
      }


      return items
    },
    headers()  {
      return [
        { text : this.$t('Name')          ,  value : 'name'                            },
        { text : this.$t('Client')        ,  value : 'user_name'                       },
        { text : this.$t('BusinessPlan')  ,  value : 'business_plan_alias'             },
        { text : this.$t('Registered')    ,  value : 'date_created' , align : 'center' },
        { text : this.$t('Status')        ,  value : 'crm_status'                      },
        { text : 'ST'                     ,  value : 'students_count'                  },
        { text : 'CT'                     ,  value : 'components_count'                },
        { text : 'MN'                     ,  value : 'managers_count'                  },
        { value : 'alias' ,  align : 'd-none' },

        { text : this.$t('Action')        , sortable : false   }
      ]
    },
    filterStatusTypes() {

      let items = JSON.parse(JSON.stringify(this.statusTypes));
      if ( this.businesses.length > 0 ) {
        items.unshift({ text : this.$t('All') + `(${this.businesses.length})` , value : null })
        items.forEach(item => {
          if ( item.value !== null) {
            let length = this.getFilterLength('crm_status', item.value)
            if ( length > 0 ) {
              item.text = item.text + ` (${length})`
            }
          }
        })
      }

      return items
    },
    filterBusinessPlans() {
      let items = [{ text : this.$t('All') , value : null }]
      let map = this.businesses.map(el=> el.business_plan_alias ).filter( (v, i, a) => a.indexOf(v) === i )
      map.forEach(item => items.push( { text : this.$t('business_plan_' + item ) + ` (${this.getFilterLength('business_plan_alias', item)})`, value : item  }))

      return items
    },
    statusTypes() {
      return [
        {  text: this.$t('Новий')         ,  value: 'new'          },
        {  text: this.$t('Контактували')  ,  value: 'contacted'    },
        {  text: this.$t('Не відповів')   ,  value: 'no_contact'   },
        {  text: this.$t('В процесі')     ,  value: 'in_process'   },
        {  text: this.$t('Чекають')       ,  value: 'waiting'   },
        {  text: this.$t('Перспективний') ,  value: 'perspective'   },
        {  text: this.$t('Cоціальний')    ,  value: 'social'   },
        {  text: this.$t('Успіх')         ,  value: 'success'      },
        {  text: this.$t('Відмова')       ,  value: 'refuse'       },
      ]

    },

    plannedMonthArrival() {
      let ammount = 0;
      if (this.businesses.length > 0 ) {
        let array =  this.businesses.map(el => el.business_plan_price  )
        array.forEach((price)=>{
          ammount += price
        })
      }
      return ammount
    },
    trialLength() {
      let quantity = 0;
      if (this.businesses.length > 0) {
        let array = this.businesses.filter(el=> el.business_plan_price === 0 )
        quantity = array.length
      }
      return quantity
    },
    payedLength() {
      return this.businesses.length - this.trialLength
    },
    fineLength() {
      let quantity = 0;
      if (this.businesses.length > 0) {
        let array = this.businesses.filter(el=> el.project !== 'westudy' )
        quantity = array.length
      }
      return quantity
    },
    westudyLength() {
      return this.businesses.length - this.fineLength
    },

  },
  methods : {

    changeTagFilter(tag) {
     if ( this.filterTags.includes(tag)) {
       let index = this.filterTags.findIndex(el => el === tag)
       if ( index === -1 ) { return }
       this.filterTags.splice(index,1)
     } else {
       this.filterTags.push(tag)
     }
    },
    getTagName(tag) {
      let tagEntity = this.tagsSelect.find(el => el.value === tag)
      if ( tagEntity ) { return tagEntity.text }
      return 'tag'
    },

    ...mapActions('adminCrmSystem', [
        'ADMIN_CRM_GET_BUSINESSES',
        'ADMIN_CRM_ADD_BUSINESS_EVENT',
        'ADMIN_CRM_EDIT_BUSINESS_STATUS',
        'ADMIN_CRM_EDIT_BUSINESS_TAGS',
        'ARCHIVE_BUSINESS' ,
    ] ),

    openMenu($event , item) {
      this.selectedBusiness = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    editAction(type,item) {
      this.selectedBusiness = item
      switch(type) {
        case 'add_note'         : this.displayAddEvent(item, 'note'); break;
        case 'add_call'         : this.displayAddEvent(item, 'call'); break;
        case 'add_video_call'   : this.displayAddEvent(item, 'video_call'); break;
        case 'add_task'         : this.displayAddEvent(item, 'task'); break;
        case 'archive_business' : this.displayArchiveBusiness(); break;
      }

      if (type.includes('add_tag_') ) {
        this.notify( 'ADD UUID: ' + type.replace('add_tag_',''))
        this.changeTag(type.replace('add_tag_',''),'add')
      }
      if (type.includes('remove_tag_')) {
        this.changeTag(type.replace('remove_tag_',''),'remove')
      }
    },
    async changeTag(tag,type) {
      let tags = this.selectedBusiness.tags
      let tagsArray = tags.map(el => el.uuid)
      if ( type === 'add' ) {
        tagsArray.push(tag)
      }
      if ( type === 'remove' ) {
        let index = tagsArray.findIndex( el => el === tag )
        if ( index === -1 ) { return }
        tagsArray.splice(index,1)
      }

      let data = {
        business_id : this.selectedBusiness.uuid,
        tags : tagsArray
      }

      let result = await this.ADMIN_CRM_EDIT_BUSINESS_TAGS(data);
      if ( !result ) { return }
      this.selectedBusiness.tags = result

    },
    async changeStatus(status,item) {
      let data = {
        uuid : item.uuid,
        status : status
      }
      let result = await this.ADMIN_CRM_EDIT_BUSINESS_STATUS(data)
      if ( !result ) { return this.notify(this.$t('Error')) }
      item.crm_status = status
      this.notify(this.$t('ChangesSaved'))
    },
    async addEvent() {
      this.eventEntity.text = this.entityData.text.replace(/\n/gi, '<br>');
      if (!await this.ADMIN_CRM_ADD_BUSINESS_EVENT(this.eventEntity)) {
        return this.notify(this.$t('Error'))
      }
      this.displayAddEventDialog = false
      this.notify('Обʼєкт збережено')
    },
    displayAddEvent(item, type) {
      this.newCardType = type
      this.eventType = this.getEventName(type)
      this.eventEntity = JSON.parse(JSON.stringify(this.defaultEventEntity))
      this.eventEntity.type = type
      this.eventEntity.business_id = this.selectedBusiness.uuid
      this.displayAddEventDialog = true
    },
    displayArchiveBusiness() {
      this.displayConfirmArchive = true
    },
    async archiveBusiness() {
      let result = await this.ARCHIVE_BUSINESS(this.selectedBusiness.uuid)
      if ( !result ) { return this.notify('Помилка') }
      let index = this.businesses.findIndex(el=>el.uuid === this.selectedBusiness.uuid)
      if ( index !== -1 ) {
        this.businesses.splice(index,1)
      }
      this.displayConfirmArchive = false
    },

    // technical

    getFilterLength(field, value) {
      if ( this.businesses.length === 0 ) { return }
      return this.businesses.map(el=>el[field]).filter(el=>el === value ).length
    },

    editActionTypes(item) {
      return [
        { text: 'Переглянути картку' , icon : 'mdi-eye'   ,  route : this.localeLink('dashboard/businesses/' + item.uuid) },
        { text: 'Переглянути сайт'   , icon : 'mdi-web'   ,  route : this.localeLink( item.alias) },
        { text: 'Увійти як',
          icon : 'mdi-login'   ,
          action : () => this.loginAsUser(item.user_id , this.localeLink('dashboard/businesses') , this.localeLink(`dashboard/${item.alias}`))
        },
        { text: this.$t('Tags'),  icon : 'mdi-tag', children : this.getBusinessFreeTagsSelect(item.tags) },
        { text: this.$t('Add') ,  icon : 'mdi-plus' , children : [
            { text: this.$t('Note')      , icon : 'mdi-plus'  ,  value : 'add_note' },
            { text: this.$t('Call')      , icon : 'mdi-plus'  ,  value : 'add_call' },
            { text: this.$t('VideoCall') , icon : 'mdi-plus'  ,  value : 'add_video_call' },
            { text: this.$t('Task')      , icon : 'mdi-plus'  ,  value : 'add_task' },
            { text: this.$t('Bug')      , icon : 'mdi-plus'  ,  value : 'add_bug' },
          ]
        },
        { text: 'Приховати'          , icon : 'mdi-close' ,  value : 'archive_business' }
      ]
    },
    getBusinessFreeTagsSelect(tags) {

      let items = JSON.parse(JSON.stringify(this.tagsSelect) )

      if (!tags ) { return [] }

      let  tagsArray = tags.length > 0 ?  tags.map( el => el.uuid ) : []

      items.forEach((item) => {
        if ( tagsArray.includes(item.value) ) {
          item.value = 'remove_tag_' + item.value
          item.icon = 'mdi-minus'
        } else {
          item.value = 'add_tag_'    + item.value
          item.icon = 'mdi-plus'
        }
      })


      return items
    },

    getCRMStatusText(status) {
      switch(status) {
        case 'new' : return 'Новий'
        case 'contacted' : return 'Контактували'
        case 'no_contact' : return 'Не відповів'
        case 'in_process' : return 'В процесі'
        case 'success' : return 'Успіх'
        case 'refuse' : return 'Відмова'
        default : return 'Новий'
      }
    },
    getCRMStatusIcon(status) {
      switch(status) {
        default :  return 'mdi-cog'
      }
    },
    getEventName(event) {
      switch(event) {
        case 'note'       : return  this.$t('Note');
        case 'call'       : return  this.$t('Call');
        case 'video_call' : return  this.$t('VideoCall');
        case 'task'       : return  this.$t('Task');
        default : return 'Не визначено'
      }
    },

    async initPage() {
      let result = await this.ADMIN_CRM_GET_BUSINESSES(this.project);
      if (!result ) { return }
      this.businesses = result.businesses
      this.tagsSelect = result.tags
    }
  },
  async mounted() {
    // this.project = this.$store.state.project
    this.project = this.$store.state.project === 'westudy' ? 'westudy' : 'all'

    this.initPage()

  }
}
</script>

<style scoped lang="scss">
</style>